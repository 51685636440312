import React from 'react';
import { useNavigate } from 'react-router-dom';
import Col from '@visa/vds/col';
import VDSFooter from '@visa/vds/footer';
import FooterCopyright from '@visa/vds/footer-copyright';
import FooterLinks from '@visa/vds/footer-links';
import FooterLogoCol from '@visa/vds/footer-logo-col';
import FooterRegion from '@visa/vds/footer-region';
import FooterRegionCol from '@visa/vds/footer-region-col';
import FooterRegionIcon from '@visa/vds/footer-region-icon';
import FooterRegionLabel from '@visa/vds/footer-region-label';
import Grid from '@visa/vds/grid';
import Icon from '@visa/vds/icon';
import Row from '@visa/vds/row';
import Select from '@visa/vds/select';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { RedirectRoutes } from '../../../constants';
import { Utils } from '../../../utils';
import { LinkWrapper } from '../../core-ui';
import styles from './footer.module.scss';

type footerProps = {
  [key: string]: any;
};

const Footer: React.FC<footerProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { checkEligibility, userAuth } = useSelector((store: any) => store);
  const countryConfiguration = checkEligibility.countryConfiguration;

  if (localStorage && localStorage.getItem('i18nextLng')) {
    document.documentElement.lang = localStorage.getItem('i18nextLng') || '';
  }

  const handleLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    Utils.setLocale(value);
    Utils.loadScript(Utils.getCookieConsentScriptUrl(value));
    document.documentElement.lang = value;
  };

  return (
    <Grid type="fluid">
      <Row>
        <Col noGutter>
          <VDSFooter position="relative" className={styles['vds-footer'] + ' styleguide-navbar-example'}>
            <Grid type="fluid" className={styles['vds-grid']}>
              <Row noGutter className={styles['row']}>
                <FooterLogoCol xs={4} md={8} lg={9} xl={9} className={styles['footer-logo']}>
                  <Row noGutter className={styles['row']}>
                    <Col xs={4} md={3} lg={4} xl={3} className={styles['copyright-col']}>
                      <FooterCopyright>
                        {t('footer.copyright')} © {`${new Date().getFullYear()}`} Visa. {t('footer.allRightsReserved')}
                      </FooterCopyright>
                    </Col>
                    <Col xs={3} md={4} lg={8} xl={9} className={styles['links-col']}>
                      <FooterLinks tag="ul">
                        <li>
                          <LinkWrapper
                            // href={RedirectRoutes.terms}
                            href={Utils.getTermsLink(userAuth.isFullyLogged)}
                            ariaLabel={t('footer.tac')}
                          >
                            {t('footer.tac')}
                          </LinkWrapper>
                        </li>
                        <li>
                          <LinkWrapper
                            // href={RedirectRoutes.faq}
                            href={Utils.getFaqLink(userAuth.isFullyLogged)}
                            ariaLabel={t('footer.faq')}
                          >
                            {t('footer.faq')}
                          </LinkWrapper>
                        </li>
                        <li>
                          <LinkWrapper
                            href={countryConfiguration?.privacyUrl}
                            ariaLabel={t('footer.privacy')}
                            openInNewTab
                          >
                            {t('footer.privacy')}
                          </LinkWrapper>
                        </li>
                        <li>
                          <LinkWrapper
                            href={Utils.getCookieConsentHtmlUrl()}
                            ariaLabel={t('footer.cookie')}
                            openInNewTab
                          >
                            {t('footer.cookie')}
                          </LinkWrapper>
                        </li>
                        <li>
                          <LinkWrapper
                            href="https://www.visa.com/purchasealerts"
                            ariaLabel={t('footer.learnMoreAriaLabel')}
                            openInNewTab
                          >
                            {t('footer.learnMore')}
                          </LinkWrapper>
                        </li>
                      </FooterLinks>
                    </Col>
                  </Row>
                </FooterLogoCol>
                <FooterRegionCol
                  xs={4}
                  lg={3}
                  md={4}
                  xl={3}
                  valign="center"
                  lgTextAlign="right"
                  xlTextAlign="right"
                  xxlTextAlign="right"
                  className={styles['region-col']}
                >
                  {countryConfiguration.supportedLocales.length > 1 &&
                  !Utils.isUS() &&
                  countryConfiguration.supportedLocales[0]['locale'] ? (
                    <FooterRegion className={styles['footer-region']}>
                      <FooterRegionIcon>
                        <Icon name="language" resolution="low" />
                      </FooterRegionIcon>
                      <FooterRegionLabel className={styles['footer-region-label']}>
                        <Select
                          className={styles['footer-select-dropdown']}
                          label=""
                          onChange={handleLanguage}
                          key={i18next.language}
                          defaultValue={i18next.language}
                          aria-label={t('footer.language')}
                        >
                          {Utils.sortSupportedLocales(countryConfiguration?.supportedLocales)?.map(lang => {
                            return (
                              <option key={lang.name} value={lang.locale}>
                                {lang.name}
                              </option>
                            );
                          })}
                        </Select>
                      </FooterRegionLabel>
                    </FooterRegion>
                  ) : null}
                </FooterRegionCol>
              </Row>
            </Grid>
          </VDSFooter>
        </Col>
      </Row>
    </Grid>
  );
};
export default Footer;
