import { put, takeLatest, call, select } from 'redux-saga/effects';
import { ajaxHandler, apiPaths } from '../../../setup';
import { replaceUserGuid } from '../../../utils/utils';
import { RedirectRoutes } from '../../../constants';
import { showAlert } from '../alert/global-alert-reducer';
import { clearSelectedEditCard } from '../../../pages/home/components/manage-cards/manage-cards-reducer';
import { Utils } from '../../../utils';
import { showSpinner, hideSpinner } from '../spinner/spinner-reducer';
import { getAddPayload, getEncryptedAPIPayload } from './utility';

import {
  unverifiedEmailFailure,
  unverifiedEmailSuccess,
  deleteUnverifiedEmail,
  deleteUnverifiedPhone,
  unverifiedPhoneSuccess,
  unverifiedPhoneFailure,
  postPaymentInstruments,
  paymentInstrumentsSuccess,
  paymentInstrumentsFailure,
  patchPaymentInstruments,
  patchPaymentInstrumentsSuccess,
  patchPaymentInstrumentsFailure,
  deleteCard,
  deleteCardSuccess,
  deleteCardFailure,
  createEncryptedPayload
} from './add-edit-card-reducer';
import { removeDeletedContactFromProfile } from '../../../pages/home/home-reducer';

function* deleteUnverifiedEmailHandler(action) {
  const { payload } = action;
  const { userAuth } = yield select((store: any) => store);
  const unverifiedEmailUrl = replaceUserGuid(apiPaths.emailUrl, userAuth.userGuid);
  const response = yield call(ajaxHandler, `${unverifiedEmailUrl}/${payload.unverifiedEmailContact.guid}`, false, {
    method: 'delete',
    headers: {
      access_token: userAuth.accessToken
    },
    urlWithPlaceholder: apiPaths.emailUrl
  });
  if (!response.error) {
    yield put(unverifiedEmailSuccess());
  } else {
    yield put(unverifiedEmailFailure(response.error));
  }
}

function* deleteUnverifiedPhoneHandler(action) {
  const { payload } = action;
  const { userAuth } = yield select((store: any) => store);
  const unverifiedPhoneUrl = replaceUserGuid(apiPaths.phoneUrl, userAuth.userGuid);
  const response = yield call(ajaxHandler, `${unverifiedPhoneUrl}/${payload.unverifiedPhoneContact.guid}`, false, {
    method: 'delete',
    headers: {
      access_token: userAuth.accessToken
    },
    urlWithPlaceholder: apiPaths.phoneUrl
  });
  if (!response.error) {
    yield put(unverifiedPhoneSuccess());
    yield put(removeDeletedContactFromProfile(payload.unverifiedPhoneContact.guid));
  } else {
    yield put(unverifiedPhoneFailure(response.error));
  }
}

function* postPaymentInstrumentsHandler(action) {
  const { data, navigate, t } = action.payload;
  const { userAuth, alerts, addEditCard, userDetails } = yield select((store: any) => store);
  const vbaToken = yield call(Utils.vbaTokenGenerator);
  data['vbaToken'] = vbaToken;
  const paymentInstrumentsUrl = replaceUserGuid(apiPaths.payment.baseUrl, userAuth.userGuid);
  const response = yield call(ajaxHandler, paymentInstrumentsUrl, true, {
    method: 'post',
    data: data,
    headers: {
      access_token: userAuth.accessToken
    },
    urlWithPlaceholder: apiPaths.payment.baseUrl
  });

  if (!response.error) {
    yield put(paymentInstrumentsSuccess());
    if (userDetails.numberOfCards === 0) {
      navigate(RedirectRoutes.home);
    } else {
      navigate(RedirectRoutes.homeManageCards);
    }
    yield put(
      showAlert({
        type: 'SUCCESS',
        isDynamic: true,
        message: `alerts.successMsg`,
        dynamicParts: [addEditCard.postPaymentInstruments.last4Digits]
      })
    );
  } else {
    yield put(paymentInstrumentsFailure(response.error));
    const statusMessageMap = {
      "PAS_5005": 'add_edit_card.error.sdk.unknown',
      "PAS_5002": 'add_edit_card.error.sdk.failure',
      "PAS_5003": 'add_edit_card.error.sdk.unknown',
      "PAS_5004": 'add_edit_card.error.sdk.cancelled',
    };
    const message = statusMessageMap[response.error?.reason];
  
    if (message) {
      yield put(
        showAlert({
          type: 'ERROR',
          message: message
        })
      );
    }
  }
}

function* patchPaymentInstrumentsHandler(action) {
  const { data, navigate, card_id } = action.payload;
  const { userAuth } = yield select((store: any) => store);
  const patchpaymentInstrumentsUrl = replaceUserGuid(apiPaths.payment.baseUrl, userAuth.userGuid);
  const response = yield call(ajaxHandler, `${patchpaymentInstrumentsUrl}/${card_id}`, true, {
    method: 'patch',
    data: data,
    headers: {
      access_token: userAuth.accessToken
    },
    urlWithPlaceholder: apiPaths.payment.baseUrl
  });
  if (!response.error) {
    yield put(patchPaymentInstrumentsSuccess(response.data));
    yield put(clearSelectedEditCard()); // clear selected for edit after successfull patch api
    navigate(RedirectRoutes.homeManageCards);
    yield put(
      showAlert({
        type: 'SUCCESS',
        message: 'add_edit_card.success.editCard'
      })
    );
  } else {
    yield put(patchPaymentInstrumentsFailure(response.error));
  }
}

function* deleteCardHandler(action) {
  const { card_id, navigate } = action.payload;
  const { userAuth } = yield select((store: any) => store);
  const deleteCardUrl = replaceUserGuid(apiPaths.payment.baseUrl, userAuth.userGuid);
  const response = yield call(ajaxHandler, `${deleteCardUrl}/${card_id}`, true, {
    method: 'delete',
    headers: {
      access_token: userAuth.accessToken
    },
    urlWithPlaceholder: apiPaths.payment.baseUrl
  });
  if (!response.error) {
    yield put(deleteCardSuccess());
    navigate(RedirectRoutes.home);
  } else {
    yield put(deleteCardFailure(response.error));
  }
}
function* createEncryptedPayloadHandler(action) {
  const { data, navigate, t} = action.payload;
  const encryptedPayload = getEncryptedAPIPayload(data);
  const { userAuth, app } = yield select((store: any) => store);
  const { encNonce } = app;
  encryptedPayload['encNonce'] = encNonce;
  let identityToken = null;
  let isThreeDSCancelledError = false;
  try {
    const response = yield call(ajaxHandler, apiPaths.sdk.createEncryptedPayloadUrl, true, {
      method: 'post',
      data: encryptedPayload,
      headers: {
        access_token: userAuth.accessToken
      },
      urlWithPlaceholder: apiPaths.sdk.createEncryptedPayloadUrl
    });

    if (!response.error) {
      yield put(showSpinner());
      const stepUpResponse = yield call([window.webSDKInstance, window.webSDKInstance?.getStepUpAuthentication],response.data?.encPayload);
      identityToken = stepUpResponse?.identityToken;
    } 
  } catch (error: any) {
    const errorMessage = error?.message || error;
    let errorObject;
    try {
      errorObject = JSON.parse(errorMessage);
    } catch (err) {
      console.error(errorMessage);
    }

    if (errorObject && errorObject?.type === 'ThreeDSUserCancelledError') {
        isThreeDSCancelledError = true;
        yield put(
          showAlert({
            type: 'ERROR',
            message: 'add_edit_card.error.sdk.cancelled'
          })
        );
    }
  } finally {
    yield put(hideSpinner());
  }

  //Only in case of 3ds cancelled error we need to cancel add card operation
  if (!isThreeDSCancelledError) {
    const paymentInstrumentData = getAddPayload(data);
    const payload = {
      paymentInstrument: paymentInstrumentData
    };
    payload['identityToken'] = identityToken;

    yield put(postPaymentInstruments({ data: payload, navigate, t }));
  }
}

export default function* watchAddEditCard() {
  yield takeLatest(deleteUnverifiedEmail, deleteUnverifiedEmailHandler),
  yield takeLatest(deleteUnverifiedPhone, deleteUnverifiedPhoneHandler),
  yield takeLatest(postPaymentInstruments, postPaymentInstrumentsHandler),
  yield takeLatest(patchPaymentInstruments, patchPaymentInstrumentsHandler),
  yield takeLatest(deleteCard, deleteCardHandler);
  yield takeLatest(createEncryptedPayload, createEncryptedPayloadHandler);

}
